.wrap{
    opacity: 0;
}

#loader{
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 99999;
    background-color: #fff;
    transition: 0.3s;
    transform: rotate(0.0001deg);

    &.fadeOutUp {
        top: -100%;
    }

    .logo img {
        width: 100%;
        max-width: 460px;
    }

    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    
}

.main-image {
    &.target{
        opacity: 1;
    }
}

@media screen and (min-width: 769px){
    #loader .logo {
        margin: auto;
        text-align: center;
    }
}

@media screen and (max-width: 768px){
    #loader .logo {
        width: 80%;
    }
}
